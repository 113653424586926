const names = {
  1990: [
    "Свадебный сон",
    "Туман",
    "В интерьере",
    "Первый снег",
    "В гостях у художника",
    "У окна",
    "Муромские холмы",
    "На окне",
    "Натюрморт",
    "На Оке",
    "Сельская свадьба",
    "В гостях",
  ],
  2000: [
    "Пейзаж с красным зонтиком 2007 х., м. 80x90",
    "Дама в голубом 2008 х., м. 120х110",
    "Площадь Маяковского 2009 х., м. 110х120",
    "В Серпухове 2008 х., м. 92х110",
    "Интерьер с белым кувшином 2007 х., м. 110х100",
    "Летний день на Оке 2007 х., м. 100х125",
    "Собор Василия Блаженного 2009 х., м. 40х40",
    "Ню  2010 х., м. 80х120",
    "Театральная площадь 2010 х., м. 100х110",
    "Art 2009 х., м. 85х65",
    "Таруса 2009 х., м. 110х120",
    "Осень в Серпухове 2010 х., м. 60х90",
  ],
  2010: [
    "Дерево. 180х120. 2014",
    "Праздник. 110х110. 2013",
    "Лесоруб. 90х80. 2014",
    "Тени забытых богов. 120х110. 2014",
    "Оберег. 140х90. 2015",
    "Карусель. 130х130. 2015",
    "Сельский праздник. Диптих 90х50. 2015",
    "Сельский праздник. Диптих 90х50. 2015",
    "Повозка. 80х100. 2013",
    "Оберег Черная курица. 100х80. 2014",
    "Рождество. 80х80. 2014-2",
    "Леонародовы поля. 180x120. 2014",
  ],
  new: [
    "Памяти поэта Николая Рубцова 80x80 2020",
    "Омар Хаям 60х80 2021",
    "Попутчик 70х50 2020",
    "Гимнастки 120х100 2021",
  ],
  shadows: [
    "#4f9336 ",
    "#586ace",
    "#d2b828",
    "#e16b97",
    "#8ce3ec",
    "#f08907",
    "#57abb9",
    "#f7bf18",
    "#4e6bdd",
    "#e81919",
    "#289ee6",
    "#ecb921",
  ],
}
export default names
