import React, { useEffect, useState } from "react"
import Gallery from "../components/Gallery"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

let periods

const Works = ({ data }) => {
  const { n1990, n2000, n2010, nnew } = data

  const arrayFromQuery = data => {
    const array = data.nodes.sort((a, b) => {
      return a.name - b.name
    })
    if (array[0].name !== "0") return array.slice(1)
    else return array
  }

  const [loading] = useState(false)
  const [period, setPeriod] = useState({
    name: "2000",
    pictures: arrayFromQuery(n2000),
  })

  useEffect(() => {
    periods = document.querySelector(".periods")
    document.addEventListener("scroll", onScroll)
  }, [])

  // scroll
  const onScroll = () => {
    if (periods) {
      const currentScrollPos = window.pageYOffset
      if (currentScrollPos <= 40) periods.style.top = "5.4rem"
      else {
        periods.style.top = "4.5rem"
      }
    }
  }

  const changePeriod = (e, period) => {
    // loading try release
    // setLoading(true)
    // setTimeout(() => setLoading(false), 100)

    let activeBtn = document.querySelector(".periods-active")
    activeBtn.classList.remove("periods-active")
    e.target.classList.add("periods-active")
    setPeriod({
      name: e.target.dataset.date,
      pictures: arrayFromQuery(period),
    })
  }

  return (
    <>
      <Seo title="Работы" />
      <section className="periods-section">
        <div className="periods-header">
          <h4>Периоды творчества</h4>
        </div>
        <ul className="periods">
          <button onClick={e => changePeriod(e, n1990)}>
            <li className="periods-li" data-date="1990">
              1980 - 2000 гг.
            </li>
          </button>
          <button onClick={e => changePeriod(e, n2000)}>
            <li className="periods-li periods-active" data-date="2000">
              2000 - 2010 гг.
            </li>
          </button>
          <button onClick={e => changePeriod(e, n2010)}>
            <li className="periods-li " data-date="2010">
              2010 - 2020 гг.
            </li>
          </button>
          <button onClick={e => changePeriod(e, nnew)}>
            <li className="periods-li" data-date="new">
              Новые работы
            </li>
          </button>
        </ul>
        {!loading && <Gallery period={period} />}
        {loading && <div className="loading"></div>}
      </section>
    </>
  )
}

export const query = graphql`
  {
    n1990: allFile(filter: { relativeDirectory: { eq: "1990" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
            formats: WEBP
            quality: 50
          )
        }
        name
      }
    }
    n2000: allFile(filter: { relativeDirectory: { eq: "2000" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
            formats: WEBP
            quality: 50
          )
        }
        name
      }
    }
    n2010: allFile(filter: { relativeDirectory: { eq: "2010" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
            formats: WEBP
            quality: 50
          )
        }
        name
      }
    }
    nnew: allFile(filter: { relativeDirectory: { eq: "new" } }) {
      nodes {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
            formats: WEBP
            quality: 50
          )
        }
        name
      }
    }
  }
`

export default Works
