import React, { useEffect } from "react"
import ModalPicture from "./ModalPicture"
import { useGalleryContext } from "../context/Context"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import names from "../assets/data/names"

let list

const Gallery = ({ period }) => {
  const { showModal, modalToggle } = useGalleryContext()
  const { name, pictures } = period

  useEffect(() => (list = document.querySelectorAll(".img-list")))

  return (
    <section className="img-container">
      {pictures.map(picture => {
        const { name: id } = picture
        const image = getImage(picture)
        return (
          <div className="img-list">
            <GatsbyImage
              imgClassName={`img-${id} img-list__images`}
              image={image}
              key={id}
              alt="a picture"
              data-id={id}
              data-index={id}
              onClick={e => showModal(e)}
            />
            <p>{names[name][id]}</p>
          </div>
        )
      })}
      {/* {createPictures(date, total).map(picture => {
        const { path, id, title, description } = picture
        return (
          <div className="img-list">
            <img
              key={id}
              src={path}
              alt={title}
              data-id={id}
              data-index={id}
              className={`img-${id} img-list__img`}
              onClick={e => showModal(e)}
            />
            <p>{description}</p>
          </div>
        )
      })} */}
      {modalToggle && (
        <div className="modal">
          <ModalPicture list={list} period={name} />
        </div>
      )}
    </section>
  )
}

export default Gallery
