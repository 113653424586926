import React, { useEffect } from "react"
import { colors } from "../assets/data/colors"
import names from "../assets/data/names"
import { useGalleryContext } from "../context/Context"

let back,
  forward,
  modalPicture,
  modalPictureImg,
  description,
  descriptionText,
  // swipe
  touchStartX,
  touchMoveX,
  touchStartY,
  touchMoveY,
  event,
  lengthOfSwipe,
  lengthOfSwipeModal,
  lengthOfVerticalSwipe,
  lengthOfVerticalSwipeModal,
  background,
  colorsIndex = 0

const ModalPicture = ({ list, period }) => {
  const {
    showModal,
    index,
    setIndex,
    picturePath,
    setPicturePath,
    showArrows,
    addEvent,
  } = useGalleryContext()

  useEffect(() => {
    back = document.querySelector(".back")
    forward = document.querySelector(".forward")
    modalPicture = document.querySelector(".modal-picture")
    modalPictureImg = document.querySelector(".modal-picture__img")
    description = document.querySelector(".desc-modal")
    descriptionText = document.querySelector(".desc-modal p")
    background = document.querySelector(".modal")
    resizeObserver.observe(document.documentElement)

    description.style.width = `${modalPicture.offsetWidth - 120}px`
    description.style.zIndex = "-1"
    description.style.marginLeft = "65px"
    description.style.marginRight = "65px"
    description.style.paddingRight = ".3rem"
    addEvent("touchstart", modalPicture, touchStart)
    addEvent("touchmove", modalPicture, touchMove)
    setIndex(index + 1)
  }, [])

  useEffect(() => addEvent("touchend", modalPicture, touchEnd), [index])

  useEffect(() => showArrows(back, forward, list), [index])

  // Modal background color
  useEffect(() => toggleBackground, [index])
  const toggleBackground = () => {
    background.style.background = colors[colorsIndex]
    colorsIndex += 1
    if (colorsIndex === colors.length - 1) colorsIndex = 0
  }

  // hotkeys
  const hotkeys = e => {
    const key = e.key

    if (key === "Escape") showModal(e)
    if (key === "ArrowLeft") {
      toggleModal("back")
    }
    if (key === "ArrowRight") {
      toggleModal("forward")
    }
  }

  useEffect(() => addEvent("keydown", document, hotkeys), [index])

  // modal picture moving
  const toggleModal = action => {
    let newIndex
    let distance
    if (action === "back") {
      newIndex = index - 1
      if (newIndex < 0) return
      distance = "-200"
    }

    if (action === "forward") {
      newIndex = index + 1
      if (newIndex === list.length) return
      distance = "200"
    }

    setIndex(newIndex)
    moveModalPicture(modalPicture, distance, 400, newIndex)
    toggleDescription(400)
  }

  const moveModalPicture = (modalPicture, coordinates, delay, newIndex) => {
    modalPicture.style.transform = `translateX(${coordinates}px)`
    modalPicture.style.opacity = "0"

    setTimeout(() => {
      setPicturePath(
        document.querySelector(`.img-${newIndex}`).getAttribute("src")
      )
      modalPicture.style.opacity = "1"
      modalPicture.style.transform = `translateX(0)`
    }, delay)
  }
  // observer
  const resizeObserver = new ResizeObserver(entries => {
    const clientWidth = entries[0].target.clientWidth

    if (clientWidth < 970) {
      description.style.transform = `translateY(68.9vh)`
    }
    if (clientWidth < 700) {
      description.style.transform = `translateY(74vh)`
    }
    if (clientWidth < 600) {
      description.style.transform = `translateY(78.1vh)`
    }
  })

  const toggleDescription = delay => {
    descriptionText.style.opacity = "0"
    description.style.zIndex = "-1"

    setTimeout(() => {
      descriptionText.style.opacity = "1"
      const widthOfPicture = modalPicture.offsetWidth
      description.style.width = `${widthOfPicture - 120}px`
    }, delay)
  }

  // swipe
  const handleGesture = () => {
    if (lengthOfSwipeModal < 150 && lengthOfVerticalSwipeModal < 75) {
      modalPicture.style.transform = `translateX(${-lengthOfSwipe}px)`
    }
    if (lengthOfVerticalSwipeModal < 150 && lengthOfSwipeModal < 75) {
      modalPicture.style.transform = `translateY(${-lengthOfVerticalSwipe}px)`
    }
  }

  const handleSwipe = () => {
    if (lengthOfVerticalSwipeModal < 100) {
      if (lengthOfSwipe < -75) {
        toggleModal("back")
      }
      if (lengthOfSwipe > 75) {
        toggleModal("forward")
      }
    }
    if (lengthOfVerticalSwipeModal > 75 && lengthOfSwipeModal < 100) {
      showModal(event)
    } else modalPicture.style.transform = `translate(0, 0)`
    touchStartX = 0
    touchMoveX = 0
    touchStartY = 0
    touchMoveY = 0
    event = null
    lengthOfSwipe = 0
    lengthOfSwipeModal = 0
    lengthOfVerticalSwipe = 0
    lengthOfVerticalSwipeModal = 0
  }

  const touchStart = e => {
    document.body.style.overflowY = "hidden"
    event = e
    touchStartX = e.changedTouches[0].clientX
    touchStartY = e.changedTouches[0].clientY
  }

  const touchMove = e => {
    touchMoveX = e.changedTouches[0].clientX
    touchMoveY = e.changedTouches[0].clientY
    lengthOfSwipe = touchStartX - touchMoveX
    lengthOfSwipeModal = Math.abs(touchStartX - touchMoveX)
    lengthOfVerticalSwipe = touchStartY - touchMoveY
    lengthOfVerticalSwipeModal = Math.abs(touchStartY - touchMoveY)
    handleGesture()
  }

  const touchEnd = e => {
    handleSwipe()
    document.body.style.overflowY = "scroll"
  }

  return (
    <div className="modal-container">
      <button className="close-button" onClick={showModal}>
        <span className="close-button__left-part"></span>
        <span className="close-button__right-part"></span>
      </button>
      <button
        className="backward-button back"
        onClick={() => toggleModal("back", back, forward)}
      />
      <div className="modal-picture">
        <img
          src={picturePath}
          alt="title"
          className="modal-picture__img"
          onClick={e => showModal(e)}
        />
        <div className="desc-modal">
          <p>{names[period][index]}</p>
        </div>
      </div>
      <button
        className="forward-button forward"
        onClick={() => toggleModal("forward", back, forward)}
      />
    </div>
  )
}

export default ModalPicture
